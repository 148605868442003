body {
    margin: 0;
    font-family: "Mulish",'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #696765;
    background: #FFFFFF;
    text-decoration: none;
}

code {
    font-family: Mulish, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
    text-decoration: none;
    color: #696765;
}

.wrapper {
    max-width: 1366px;
    margin: 0 auto;
    box-sizing: border-box;
}

/* 1 - BLOCK HEADER */
.headerBlock {
    background: #FFFFFF;
    color: #696765;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 0 40px;
    position: relative;
    margin-top: 40px;
    margin-bottom: 80px;
}
.headerBlock_logo {
    display: flex;
    justify-content: center;
    align-items: center;
}
.headerBlock_logo img{
    display: block;
}
.headerBlock_nav {
    display: flex;
    justify-content: space-between;
}
.headerBlock_select {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    justify-content: flex-end;
    text-align: start;
    grid-column-gap: 20px;
    margin-right: 120px;

}
.headerBlock_select > div:nth-child(4) {
    display: block;
}
.headerBlock_select a{

}

.headerBlock_language{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 60px;
    cursor: pointer;
}

/* 2 - BLOCK Hero */
.heroBlock {
    display: block;
    position: relative;
    max-width: 1366px;
}
/*.heroBlock img{*/
/*    display: block;*/
/*    position: relative;*/
/*    max-width: 1366px;*/
/*}*/
.heroBlock_logo {
    display: block;
    height: 656px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.heroBlock_text {
    display: block;
    position: absolute;

    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    color: #FFFFFF;
    text-align: right;
    top: 48%;
    right: 80px;

}



/* 3 - BLOCK AboutUs */

.AboutUsBlock {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin: 100px auto;
}
.AboutUsBlock_Name {
    display: block;
    font-size: 42px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF00008A;
    text-transform: uppercase;

}
.AboutUsBlock_Graf {
    display: block;
}

/* 4- BLOCK AboutUs_DescBlock */

.AboutUs_DescBlock{
    display: flex;
    justify-content: space-between;
    position: relative;
}

.AboutUs_Desc_Img {
    display: block;
    max-width: 763px;
    height: 231px;
}

.AboutUs_Desc_Text{
    display: flex;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    max-width: 442px;
    text-align: justify;
    align-items: center;
    padding-right: 120px;
}

/* 5- BLOCK OurTeamExperts */
.OurTeamExperts_Block {
    display: flex;
    min-height: 231px;
    padding: 0 100px;
    position: relative;
    margin: 130px auto 0px;
}

.OurTeamExperts_Left{
    display: block;
    width: 50%;
    font-size: 42px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF00008A;
    text-transform: uppercase;
    /*margin: 0 40px;*/

}
.OurTeamExperts_Right {
    display: block;
    width: 50%;

    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: justify;
    max-width: 556px;
}

.OurTeamExperts_Right_Link {
    display: block;
    margin: 5px 0;

}

.OurTeamExperts_Right_Link a {
    display: flex;
    cursor: pointer;
}

.OurTeamExperts_Right_Link_text {
    display: block;
    padding-left: 10px;
    cursor: pointer;
}
.OurTeamExperts_Right_Link_text.DopFooter {
    display: block;
    padding-left: 0px !important;
    cursor: pointer;
}
.Footer_divBot.DopFooterEventy {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
}

.Frame_DopFooter {
    cursor: pointer;
}

.imageModalFrame70 {
    /*max-width: 100% !important;*/
    max-height: 90vh  !important;
}

/* 6- BLOCK OurStrhengths */
.OurStrhengths_Block {
    max-width: 1170px;
    height: 266px;
    margin: 0 auto;
    background: #0267FF12;
    display: block;
    border-radius: 20px;
    position: relative;
    padding: 40px;
    box-sizing: border-box;

}

.OurStrhengths_Name {
    display: block;
    font-size: 42px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF00008A;
    text-transform: uppercase;
}

.OurStrhengths_Grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 25px;
}

.OurStrhengths_Grid img {
    display: block;
    margin: 0 auto;
}

/* 7- BLOCK OurStrhengths */
.WhatWeDo_Block {
    display: flex;
    justify-content: space-between;
    max-width: 1170px;
    margin: 170px auto 0px;

}

.WhatWeDo_Name {
    display: block;
    font-size: 42px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF00008A;
    text-transform: uppercase;
    /*height: 140px;*/
    /*margin-top: 100px;*/
}

.WhatWeDo_Left {
    display: block;
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    /*position: relative;*/
}

.WhatWeDo_Left_BorderBLock {
    display: block;
    width: 396px;
    /*height: 636px;*/
    border: 2px solid #696765;
    border-radius: 20px;
    padding: 0px 80px;
    box-sizing: border-box;
    position: relative;
    margin-top: 110px;
    margin-bottom: 34px;

}

.WhatWeDo_Left_BorderBLock span {
    display: block;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    width: 240px;
    margin-top: 70px;
    margin-bottom: 40px;
}

.WhatWeDo_Left_BorderBLock p {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    width: 240px;

}

.WhatWeDo_Left_BorderBLock.frame5_dop {
    height: 415px;
}


.WhatWeDo_Right_BorderBLock {
    display: block;
    width: 650px;
    min-height: 216px;
    border: 2px solid #696765;
    border-radius: 20px;
    padding: 10px 80px;
    margin-top: 70px;
    margin-bottom: 120px;
    box-sizing: border-box;
    position: relative;

}

.WhatWeDo_Right_BorderBLock span {
    display: block;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    width: 530px;
    margin-top: 70px;
    margin-bottom: 40px;
}

.WhatWeDo_Right_BorderBLock p {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    width: 530px;

}

.WhatWeDo_Left_img {
    display: block;
    position: absolute;
    top: -3%;
    left: 80px;
    background: #FFFFFF;
    width: 170px;
    margin: -40px;
    padding-left: 23px;
}

.WhatWeDo_Right_img {
    display: block;
    position: absolute;
    top: -24%;
    left: 41px;
    background: #FFFFFF;
    width: 225px;
    padding-left: 37px;
    transition: filter 0.3s;
}

.WhatWeDo_Left_img:hover img,
.WhatWeDo_Right_img:hover img {
    filter: brightness(0) saturate(100%) invert(72%) sepia(30%) saturate(2646%) hue-rotate(311deg) brightness(99%) contrast(102%);
}


.WhatWeDo_Right_Link {
    display: block;
    height: 30px;
}

.WhatWeDo_Right_Link a {
    display: flex;
}

.WhatWeDo_Right_Link_text {
    display: block;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: justify;
}






/* 8  BLOCK OurPhilosophy_Block */
.OurPhilosophy_Block {
    display: flex;
    position: relative;
    flex-direction: column;
    max-width: 662px;
    box-sizing: border-box;
    margin-left: 100px;
}

.OurPhilosophy_Name {
    display: block;
    font-size: 42px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF00008A;
    text-transform: uppercase;
    margin: 40px 0;
}

.OurPhilosophy_Text {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 140px;
}

.Footer_divBot {
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 1170px;
    margin: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;

    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

}

.FooterTwo_divBot_wrapper {
    display: block;
    position: absolute;
    /*bottom: 0;*/
}

.Footer_divBot.FooterTwo {
    min-width: 1366px;
    margin: 0;
}

/*----------------------------------------------  google_translate_element  ---------------------------------------------- */
#google_translate_element .skiptranslate.goog-te-gadget {
    font-size: 0;
    line-height: 0;
    width: 10%;
}

#google_translate_element .skiptranslate.goog-te-gadget span {
    display: none;
}

#google_translate_element .skiptranslate.goog-te-gadget div select {
    display: block;
    background: url("./assets/png/world_svgrepo.png") center/cover;
    height: 40px;
    width: 40px;
    margin: 45px auto;
    border-radius: 100%;
    transition-duration: 1s;
    font-size: 0;
    padding: 0;
    color: transparent;
    text-shadow: none;
    border: none;
    cursor: pointer;
    z-index: 100;
}

#google_translate_element .skiptranslate.goog-te-gadget div select:hover {
    box-shadow: 0 0 24.5px 10px #dddddd;
}

/*tibtus*/


.languageSelectorContainer {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.languageSelectorContainer .languageSelectorButton {
    background: url("./assets/png/world_svgrepo.png") center/cover;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transition-duration: 1s;
    font-size: 0;
    padding: 0;
    color: transparent;
    text-shadow: none;
    border: none;
    cursor: pointer;
    z-index: 100;
}

.modalnewlang {
    display: none;
}

.modalnewlang.visible {
    display: block;
}

.languageSelectorContainer .languageSelectorButton :hover {
    box-shadow: 0 0 24.5px 10px #dddddd;
}





/*----------------------------------------------  google_translate_element  ---------------------------------------------- */

/*----------------------------------------------  TeamOfExperts  ---------------------------------------------- */
.TeamOfExperts {
    display: block;
    box-sizing: border-box;
    height: 2000px;
}

.TeamOfExperts_Name {
    display: block;
    font-size: 42px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF00008A;
    text-transform: uppercase;
    padding-left: 40px;
    box-sizing: border-box;
}

.TeamOfExperts_Person {
    display: flex;
    margin: 80px;
    align-content: center;
    align-items: center;

}

.TeamOfExperts_Person.one img {
    display: block;
    width: 352px;
    height: 650px;
}

.TeamOfExperts_Person.two img {
    display: block;
    width: 283px;
    height: 364px;
    margin-left: 120px;

}

.TeamOfExperts_Person.three img {
    display: block;
    width: 306px;
    height: 364px;
    margin-bottom: 120px;
}

.TeamOfExperts_Person_text {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 80px;
}

/*----------------------------------------------  CivilEngineeringWorks  ---------------------------------------------- */

.CivilEngineeringWorks {
    display: block;
    box-sizing: border-box;
    position: relative;
    max-width: 1366px;
    min-height: 3700px;
}

.CivilEngineeringWorks_Name {
    display: block;
    font-size: 42px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF00008A;
    padding-left: 40px;
    padding-top: 120px;
    box-sizing: border-box;
}

.CivilEngineeringWorks_Object_text.left {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    /*margin-left: 80px;*/

}

.CivilEngineeringWorks_Object_text.right {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: right;
}


.CivilEngineeringWorks_Object.one {
    display: block;

    position: absolute;
    top: 0;
    right: 95px;

    width: 446px;
    height: 488px;


}

.CivilEngineeringWorks_Object.two {
    display: block;
    position: absolute;
    top: 345px;
    left: 40px;
    width: 448px;
    height: 628px;
}

.CivilEngineeringWorks_Object.three {
    display: block;
    position: absolute;
    right: 95px;
    width: 606px;
    height: 367px;
    top: 720px;

}

.CivilEngineeringWorks_Object.four {
    display: flex;
    position: absolute;
    top: 1200px;
    left: 40px;
    min-width: 615px;
    height: 408px;
}

.CivilEngineeringWorks_Object.five {
    display: flex;
    position: absolute;
    right: 95px;
    min-width: 710px;
    height: 412px;
    top: 1720px;


}

.CivilEngineeringWorks_Object.six {
    display: flex;
    position: absolute;
    top: 2250px;
    left: 40px;
    min-width: 446px;
    height: 446px;
}

.CivilEngineeringWorks_Object.seven {
    display: flex;
    position: absolute;
    right: 95px;
    min-width: 574px;
    height: 398px;
    top: 2820px;
    padding-bottom: 100px;
}



.CivilEngineeringWorks_Object.four .CivilEngineeringWorks_Object_text.left {
    display: flex;
    align-items: flex-end;
    min-width: 325px;
    padding-left: 40px;
}

.CivilEngineeringWorks_Object.five .CivilEngineeringWorks_Object_text.right {
    display: flex;
    align-items: flex-end;
    min-width: 325px;
}


.CivilEngineeringWorks_Object.six .CivilEngineeringWorks_Object_text.left {
    text-align: left;
    padding-left: 40px;
}

.CivilEngineeringWorks_Object.seven .CivilEngineeringWorks_Object_text.right {
    display: flex;
    align-items: center;
    min-width: 325px;

}

@media screen and (max-width: 1150px) {
    .WhatWeDo_Block {
        display: flex;
        flex-direction: column;
    }

    .AboutUs_DescBlock {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
    }

    .AboutUs_Desc_Img {
        display: block;
        width: 100%;
        height: 231px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .headerBlock_select {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .WhatWeDo_Left_BorderBLock {
        display: block;
        width: 80%;
        margin: 0 auto;
        height: 320px;
        border: 2px solid #696765;
        border-radius: 20px;
        padding: 10px 80px;
        margin-bottom: 95px;
        box-sizing: border-box;
        position: relative;
    }

    .WhatWeDo_Left_BorderBLock p {
        width: 100%;
    }

    .WhatWeDo_Right_BorderBLock {
        display: block;
        width: 80%;
        margin: 0 auto;
        min-height: 216px;
        border: 2px solid #696765;
        border-radius: 20px;
        padding: 10px 80px;
        margin-bottom: 95px;
        box-sizing: border-box;
        position: relative;
    }

    .OurStrhengths_Block {
        height: 100%;
        width: 80%;
        margin: 0 auto;
    }

    .OurStrhengths_Grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .WhatWeDo_Right_BorderBLock p {

        width: 100%;
    }

    .OurPhilosophy_Text {
        width: 100%;
        text-align: justify;
        /*margin: 0 auto;*/
    }


}

.CivilEngineeringWorks_Object.six.Works {
    top: 250px;

}

.CivilEngineeringWorks_Object.three.Works {
    top: 780px;
    width: 541px;
}

.CivilEngineeringWorks_Object.two.Works {
    top: 1000px;
    width: 511px;
}

.CivilEngineeringWorks_Object.three.WorksPlus {
    top: 1500px;
    width: 623px;
}

.CivilEngineeringWorks_Object.two.WorksPlus {
    top: 2000px;
    width: 734px;
}

.CivilEngineeringWorks_Object.three.WorksPlusTwo {
    top: 2400px;
    width: 634px;
}

.CivilEngineeringWorks.Works {
    min-height: 3200px;
}

.CivilEngineeringWorks.Works .CivilEngineeringWorks_Name {
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    color: #696765;
}

/* Стилі для модального вікна та його фону */
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}


.ReactModal__Content.ReactModal__Content--after-open {
    border: none !important;
    inset: auto !important;
    padding: 0 !important;
}


.ReactModal__Content {
    position: relative;
    outline: none;
    text-align: center;
}

.ReactModal__Content img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    object-fit: contain;
}


.ReactModal__Content button {
    display: none;
}


@media screen and (max-width: 750px) {
    /*body {*/
    /*    width: 340px;*/
    /*    margin: 0 auto;*/
    /*}*/
    /*.wrapper {*/
    /*    width: 340px;*/
    /*    margin: 0 auto;*/
    /*}*/
    .headerBlock_select {
        margin-right: 0;
    }

    .headerBlock_select {
        display: none;
    }

    .AboutUsBlock_Graf {
        box-sizing: border-box;
        display: block;
        padding: 0 30px;
    }

    .AboutUsBlock_Graf img {
        width: 100%;
    }


    /*.headerBlock_nav {*/
    /*    display: none;*/
    /*}*/

    .Footer_divBot {

        flex-direction: column;

    }

    .Bottom_Block_tel,
    .Bottom_Block_id,
    .Bottom_Block_address {
        margin: 40px auto;
    }

    .AboutUs_Desc_Text {
        padding-top: 30px;
        width: 100%;
    }

    .OurTeamExperts_Block {
        padding: 10px;
        width: 80%;
        flex-direction: column;
        margin-bottom: 40px;

    }

    .OurTeamExperts_Right,
    .OurTeamExperts_Left {
        padding-top: 20px;
        width: 100%;

    }

    .OurStrhengths_Block {
        padding: 0px;
    }

    .WhatWeDo_Name {
        margin-top: 0px;
        height: 140px;
    }

    .WhatWeDo_Left_BorderBLock.frame5_dop {

        height: 100%;

    }

    .WhatWeDo_Right_BorderBLock,
    .WhatWeDo_Left_BorderBLock {

        width: 80%;
        margin: 0 auto;
        height: 100%;
        margin-bottom: 180px;

    }

    .WhatWeDo_Left_BorderBLock span {
        width: 100%;
        height: 100%;
    }

    .WhatWeDo_Right_BorderBLock span {
        width: 100%;
    }

    .WhatWeDo_Right_img {
        top: -9%;
        left: 33px;
        width: 150px;
    }

    .WhatWeDo_Right_Link {
        height: 63px;
    }

    .WhatWeDo_Right_Link a img {
        height: 20px;
        width: 30px;
    }

    .OurPhilosophy_Block {
        width: 80%;
        margin: 0 auto;
    }

    .TeamOfExperts {
        width: 80%;
        margin: 0 auto;
        height: 2000px;

    }

    .TeamOfExperts_Person {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
        margin-bottom: 70px;
    }

    .TeamOfExperts_Person_text {
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0 auto;

    }

    .TeamOfExperts_Person.three img {
        display: block;
        width: 306px;
        height: 364px;
        margin: 0 auto;

    }


    .FooterTwo_divBot_wrapper,
    .Footer_divBot.FooterTwo {
        width: 100%;
        min-width: 340px;
        margin: 0 auto;
    }

    .CivilEngineeringWorks {
        min-height: 2500px;
    }

    .CivilEngineeringWorks_Object.one,
    .CivilEngineeringWorks_Object.two,
    .CivilEngineeringWorks_Object.three,
    .CivilEngineeringWorks_Object.four,
    .CivilEngineeringWorks_Object.five,
    .CivilEngineeringWorks_Object.six,
    .CivilEngineeringWorks_Object.seven {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 340px;
        min-height: 100px;
        min-width: 300px;
        margin: 40px auto;

    }

    .CivilEngineeringWorks_Object.six .CivilEngineeringWorks_Object_text.left,
    .CivilEngineeringWorks_Object.four .CivilEngineeringWorks_Object_text.left {
        padding-left: 0;
    }

    .CivilEngineeringWorks_Object_text.right {
        text-align: left;
    }

    .CivilEngineeringWorks_Object.one img,
    .CivilEngineeringWorks_Object.two img,
    .CivilEngineeringWorks_Object.three img,
    .CivilEngineeringWorks_Object.four img,
    .CivilEngineeringWorks_Object.five img,
    .CivilEngineeringWorks_Object.six img,
    .CivilEngineeringWorks_Object.seven img {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 340px;
        min-height: 100px;
        min-width: 300px;
        margin: 20px auto;
    }


    .Footer_divBot {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /*align-items: center;*/
        justify-content: center;
        text-align: center;
        margin: 0;
    }

    .Bottom_Block_address {
        margin: 40px auto;
        display: block;
        position: absolute;
        top: 50%;
        width: 50%;
        left: 24%;
    }

    .AboutUs_Desc_Text {
        padding-right: 0px;
    }

    .heroBlock_text {
        display: block;
        position: absolute;
        font-size: 15px;
        font-weight: 400;
        line-height: 34px;
        color: #FFFFFF;
        text-align: right;
        top: 35%;
        right: 32px;
    }

    .heroBlock_logo {
        display: block;
        height: 341px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }


    .CivilEngineeringWorks_Object.six.Works {
        top: 100px;

    }

    .CivilEngineeringWorks_Object.three.Works {
        top: 15px;
        max-width: 340px;
    }

    .CivilEngineeringWorks_Object.two.Works {
        top: 50px;
        max-width: 340px;
        height: 490px;
    }

    .CivilEngineeringWorks_Object.three.WorksPlus {
        top: 0px;
        max-width: 340px;
    }

    .CivilEngineeringWorks_Object.two.WorksPlus {
        top: 50px;
        max-width: 340px;
        height: 340px;
    }

    .CivilEngineeringWorks_Object.three.WorksPlusTwo {
        top: 50px;
        max-width: 340px;
    }

    .CivilEngineeringWorks.Works {
        min-height: 2500px;
    }

    .CivilEngineeringWorks.Works .CivilEngineeringWorks_Name {
        font-size: 36px;
        font-weight: 400;
        line-height: 43px;
        letter-spacing: 0em;
        text-align: left;
        color: #696765;

    }

    .OurStrhengths_Name {
        font-size: 28px;
        line-height: 72px;
    }


}
